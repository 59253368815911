﻿/*
  mall-insider.js
  Mall Insider Page
*/

// include and init phone number handler
require('../../ga/scripts/partials/intlphonenumber')();
const runCaptcha = require('../../ga/scripts/partials/recaptchasubmit');

(function () {
  const resultTypes = [];
  let results = [];
  const centerSelect = document.getElementById('centers');
  const brandSelect = document.getElementById('brands');
  const selectedCenters = document.getElementById('selectedcenters');
  const selectedBrands = document.getElementById('selectedbrands');
  const countriesSelect = document.getElementById('countries') || document.getElementById('Country') || document.getElementById('txtCountry');
  const zipCode = document.getElementById('txtZip') || document.getElementById('txtZipCode');

  const favoriteOutletsCheck = function () {
    if(selectedCenters.value === '') {
      centerSelect.parentNode.classList.add('has-error');
      centerSelect.previousElementSibling.previousElementSibling.classList.add('has-error');
      centerSelect.previousElementSibling.previousElementSibling.classList.add('up');
    } else {
      centerSelect.parentNode.classList.remove('has-error');
      centerSelect.previousElementSibling.previousElementSibling.classList.remove('has-error');
      centerSelect.previousElementSibling.previousElementSibling.classList.remove('up');
    }
  };

  // because some dropdowns have default value as 0 and that doesnt work for validation
  const badDefaults = document.querySelectorAll('option[value=\'0\']');
  for(let i = 0; i < badDefaults.length; i++) {
    badDefaults[i].value = '';
  }

  const sortFavorites = function (sortables) {
    const toSort = [].slice.call(sortables.querySelectorAll('.sortable:not(.sortable-primary)'));
    toSort.sort(function (a, b) {
      const textA = a.innerHTML.toUpperCase();
      const textB = b.innerHTML.toUpperCase();
      if(textA < textB) {
        return -1;
      }
      if(textA > textB) {
        return 1;
      }
      return 0;
    }).forEach(function (el) {
      sortables.appendChild(el);
    });
  };

  const makePrimary = function (s, sortables, hiddenList) {
    if(s === null) {
      return;
    }

    if(s.classList.contains('sortable-primary')) {
      if(sortables.querySelectorAll('.sortable').length > 1) {
        s.classList.add('sortable-active');
      } else {
        s.classList.remove('sortable-active');
      }
      return;
    }

    const currentPrimary = sortables.querySelector('.sortable-primary');

    if(currentPrimary) {
      currentPrimary.classList.remove('sortable-primary');
      currentPrimary.classList.remove('sortable-active');
    }

    s.classList.add('sortable-primary');

    if(sortables.querySelectorAll('.sortable').length > 1) {
      s.classList.add('sortable-active');
    }

    const idsAsArr = hiddenList.value.split(',');
    idsAsArr.splice(idsAsArr.indexOf(s.id), 1);
    idsAsArr.unshift(s.id);
    hiddenList.value = idsAsArr.join(',');
    hiddenList.dispatchEvent(new Event('change'));
  };

  const removeFavorite = function (id, list, sortables, sortableByPrimary = true) {
    // remove the sortable
    const child = document.getElementById(id);
    child.parentNode.removeChild(child);
    // update the value
    list.value = list.value.replace(id, '').replace(',,', ',');

    if(list.value.charAt(list.value.length - 1) === ',') {
      list.value = list.value.substr(0, list.value.length - 1);
    }

    if(list.value.charAt(0) === ',') {
      list.value = list.value.substr(1, list.value.length);
    }

    document.querySelector('#centers option[value=\'' + id + '\'], #brands option[value=\'' + id + '\']').classList.remove('hidden-xs-up');
    document.querySelector('#centers option[value=\'' + id + '\'], #brands option[value=\'' + id + '\']').removeAttribute('disabled');

    // make primary
    if(sortableByPrimary) makePrimary(sortables.querySelector('.sortable:first-child'), sortables, list);
    sortFavorites(sortables);

    // float the label if theres at least one item ok
    if(list.value === '') {
      sortables.previousElementSibling.classList.remove('up');
    }

    favoriteOutletsCheck();
  };

  const createSortable = function (id, option, hiddenList, sortables, sortableByPrimary = true) {
    const sortable = document.createElement('div');
    sortable.innerHTML = option.text;
    sortable.id = id;
    sortable.classList.add('form-control');
    sortable.classList.add('sortable');
    if(sortableByPrimary) sortable.classList.add('sortable-by-primary');
    sortable.addEventListener('click', function () {
      if(sortableByPrimary) makePrimary(sortable, sortables, hiddenList);
      sortFavorites(sortables);
    });

    // close btn
    const removeBtn = document.createElement('button');
    removeBtn.type = 'button';
    removeBtn.classList.add('pull-xs-right');
    removeBtn.classList.add('no-padding');
    removeBtn.innerHTML = '<svg viewBox=\'0 0 15 15\' width=\'15\' height=\'15\' preserveAspectRatio=\'xMinYMin meet\' style=\'fill: #757575\'><use xlink:href=\'#icon-close\'></use></svg>';
    removeBtn.addEventListener('click', function (e) {
      e.stopPropagation();
      removeFavorite(sortable.id, hiddenList, sortables, sortableByPrimary);
      // document.getElementById('selectedcenters').dispatchEvent(new Event('change'));
    });

    sortable.appendChild(removeBtn);

    // add to the wrapper
    sortables.appendChild(sortable);

    // make primary
    if(sortableByPrimary) makePrimary(sortables.querySelector('.sortable:first-child'), sortables, hiddenList);

    sortFavorites(sortables);

    // hide option from the select
    option.classList.add('hidden-xs-up');
    option.setAttribute('disabled', 'true');

    // add id to the list and trim the comma
    if(hiddenList.value.indexOf(id) < 0) {
      if(hiddenList.value.length === 0) {
        hiddenList.value += id;
      } else {
        hiddenList.value += ',' + id;
      }
    }

    // float the label if theres at least one item ok
    if(hiddenList.value !== '') {
      sortables.previousElementSibling.classList.add('up');
      hiddenList.dispatchEvent(new Event('change'));
    }

    favoriteOutletsCheck();
  };

  const favoritesChange = function () {
    // element to append sortables to
    const sortables = document.querySelector('.favorite-' + this.id);

    // input for keeping track of ids
    const hiddenList = document.getElementById('selected' + this.id);

    const sortableByPrimary = this.id !== 'brands';

    if(this.value === '') {
      favoriteOutletsCheck();
      return;
    }

    // create a sortable item
    createSortable(this.value, this.options[this.selectedIndex], hiddenList, sortables, sortableByPrimary);
    // Show/Hide family checkbox for family malls only
    hiddenList.dispatchEvent(new Event('change'));

    this.value = '';
  };

  const countriesChange = function () {
    if(countriesSelect.value !== '840' && countriesSelect.value !== '124') {
      // hide and remove reqs
      zipCode.parentElement.parentElement.classList.add('hidden-xs-up');
      zipCode.removeAttribute('data-parsley-required');
      zipCode.removeAttribute('data-parsley-pattern');
      zipCode.removeAttribute('data-parsley-type');
      zipCode.value = '';
    } else {
      zipCode.parentElement.parentElement.classList.remove('hidden-xs-up');
      zipCode.setAttribute('data-parsley-required', '');
      if(countriesSelect.value === '124') {
        zipCode.style.textTransform = 'uppercase';
        $('label[for="txtZip"]').html('POSTAL CODE');
        zipCode.setAttribute('placeholder', 'POSTAL CODE');
        zipCode.setAttribute('data-parsley-pattern', '^[ABCEGHJKLMNPRSTVXYabceghjklmprstvxy]{1}\\d{1}[A-Za-z]{1}( ?){1}\\d{1}[A-Za-z]{1}\\d{1}$');
      } else {
        zipCode.style.textTransform = 'none';
        $('label[for="txtZip"]').html('ZIP CODE');
        zipCode.setAttribute('placeholder', 'ZIP CODE');
        zipCode.setAttribute('data-parsley-pattern', '^(?=.*[1-9].*)[0-9]{5}?$');
      }
    }

    // if value is us - show phone number input
    if(countriesSelect.value === '840' || countriesSelect.value === '630') {
      document.querySelector('.js-mobile-number').classList.remove('hidden-xs-up');
    } else {
      document.querySelector('.js-mobile-number').classList.add('hidden-xs-up');
      document.getElementById('PhoneNumber').value = '';
    }
  };

  const populateFavorites = function (ids, type, hiddenInput, sortableByPrimary = true) {
    const preSelected = ids.split(',');
    const sortablesContainer = document.querySelector('.favorite-' + type);

    for(let i = 0; i < preSelected.length; i++) {
      const option = document.querySelector('#' + type + ' option[value=\'' + preSelected[i] + '\']');
      if(option !== null) {
        createSortable(preSelected[i], option, hiddenInput, sortablesContainer, sortableByPrimary);
      }
    }
  };

  if(selectedCenters.value !== '' && selectedCenters.value !== '0') {
    populateFavorites(selectedCenters.value, 'centers', selectedCenters, true);
  }

  if(selectedBrands.value !== '' && selectedBrands.value !== '0') {
    populateFavorites(selectedBrands.value, 'brands', selectedBrands, false);
  }

  // autocomplete for mall name search input
  $('#MallShortName').autocomplete({
    source(request, response) {
      $.ajax({
        url: 'https://api.simon.com/v1.2/search/filteredlookup',
        dataType: 'json',
        data: {
          q: request.term,
          searchReturnType: 'MALL|MILLS|COMMCTR',
          key: '40A6F8C3-3678-410D-86A5-BAEE2804C8F2'
        },
        success(data) {
          let firstkey;
          let itemID;
          response($.map(data, function (item) {
            results = data;
            firstkey = item.mall_name;
            itemID = item.mall_id;
            if(firstkey !== '') {
              return { label: firstkey, value: firstkey, id: itemID };
            }
            return {};
          }));
        },
        complete() {
          let firstkey;
          let firstval;
          if(results.length > 0) {
            $.each(results, function (key, value) {
              firstkey = value.mall_name;
              firstval = value.mall_id;
              resultTypes.push(new Array(firstkey, firstval));
            });
          }
        }
      });
    },
    delay: 500,
    minLength: 2,
    appendTo: '.js-search-group',
    mustMatch: true,
    select: function onSelect(event, ui) {
      this.value = ui.item.label;
    },
    selectFirst: false,
    focus: function onFocus(event, ui) {
      this.value = ui.item.label;
      event.preventDefault();
    }
  });

  // Run captcha on signup page
  if(window.location.href.indexOf('/preferences') === -1) runCaptcha('MallInsiderSignUp', 'mall_insider');

  document.addEventListener('DOMContentLoaded', function () {
    countriesChange();// on submit of form check if captcha is valid
    if(document.getElementById('MallInsiderSignUp')) {
      document.getElementById('MallInsiderSignUp').addEventListener('submit', function () {
        if(!window.captchaChecked) {
          // float the label and add error styles and return
          if(document.querySelector('.js-captcha > label.floating')) document.querySelector('.js-captcha > label.floating').classList.add('up', 'has-error');

          return false;
        }

        return null;
      });
    }

    centerSelect.onchange = favoritesChange;
    brandSelect.onchange = favoritesChange;
    countriesSelect.onchange = countriesChange;
  }, false);

  window.Parsley.on('form:error', function () {
    favoriteOutletsCheck();
  });

  window.Parsley.on('form:submit', function () {
    const phoneNumber = document.getElementById('PhoneNumber');

    if(phoneNumber.classList.contains('has-error')) {
      // e.preventDefault();
      return false;
    }

    if(selectedCenters.value === '') {
      $('html').scrollTop($('#centers').offset().top - 125);
      // e.preventDefault();
      favoriteOutletsCheck();
      return false;
    }

    if(!window.captchaChecked && document.querySelector('main .js-captcha')) {
      // float the label and add error styles and return
      document.querySelector('main .js-captcha > label.floating').classList.add('up', 'has-error');
      return false;
    }

    if(zipCode.value.length === 6 && countriesSelect.value === '124') {
      const first = zipCode.value.substr(0, 3).toUpperCase();
      const last = zipCode.value.substr(3, 3).toUpperCase();

      zipCode.value = first + ' ' + last;
    } else {
      zipCode.value = zipCode.value.toUpperCase();
    }

    return true;
  });
})();
